import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  constructor( private router: Router, private datepipe:DatePipe) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    const token: string = localStorage.getItem('token');

    let request = req;

    if (token) {
      request = req.clone({
        setHeaders: {
          authorization: `Bearer ${ token }`
        }
      });
    }

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {                
        
        if (err.status==403 )  { //err.status == 0 ||  || err.status==504)
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }else if(err.status !=500){
          let token_expire_date = localStorage.getItem('token_expire_date');
          let newDate = this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss') ;
          if(newDate > token_expire_date){
            localStorage.clear();
            this.router.navigateByUrl('/login');          
          }
        }

        return throwError( err );

      })
    );
  }
}
